import { Firestore, collection, query, where } from 'firebase/firestore'

/**
 * Retrieves a filtered list of products based on the user's permissions.
 *
 * @param db - The Firestore database object.
 * @returns A computed reference to the filtered products list.
 */
export const useProductsList = (db:Firestore) => {
  const permission = usePermissions()
  const productsRef = useCollection(query(collection(db, 'briefings'), where('dashboardUrl', '!=', '')), { ssrKey: 'dashboards-query' })

  return computed(() => productsRef.value.filter(product => permission.hasBriefingPermission(product as FsbdBriefingCreate)))
}
